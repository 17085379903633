import { FormEvent, useState, useEffect, useContext } from 'react';
import { useNavigate, useSearchParams, useParams } from 'react-router-dom';
import { Form, Formik } from 'formik';

import { FacilityAPI } from 'api/FacilityAPI';
import { ClinicianAPI } from 'api/ClinicianAPI';
import { CompensationGridAPI } from 'api/CompensationGridAPI';
import { ClinicianContractAPI } from 'api/ClinicianContractAPI';

import { OutlinedButton, SubmitButton, Select, SelectWithSearch, Input, DatePicker, TextButton, TextEditor } from 'components/UIComponents';
import { SectionContainer, ActionsBar, InputFieldsWrapper } from 'components/base';

import { IClinicianContractForm } from 'interfaces/ClinicianContract/IClinicianContractForm';
import { ISelectOption } from 'interfaces/ISelectOption';
import { IClinician } from 'interfaces/Clinician/IClinician';
import { IFacility } from 'interfaces/Facility/IFacility';
import { ICompensationGridDetails } from 'interfaces/CompensationGrid/ICompensationGridDetails';
import { IContractedHour } from 'interfaces/CompensationGrid/IContractedHour';
import { ICompensationScale } from 'interfaces/CompensationGrid/ICompensationScale';
import { ICompensationRate } from 'interfaces/CompensationGrid/ICompensationRate';
import { IClinicianContractDetails } from 'interfaces/ClinicianContract/IClinicianContractDetails';

import { ClinicianTitleType, ContractEngagementType, ContractType } from 'types';

import { useToast } from 'context/ToastContext';
import { ModalContext } from 'context/ModalContext';

import { contractTypeOptions, clinicianTitleOptions } from 'constants/compensationGridsConstants';
import {
  clinicianTitleToCompensationGridClinicianTitleMapper,
  confirmModalTitle,
  contractEngagementToCompensationGridEngagementMapper,
  contractEngagementTypeOptions,
  initialClinicianContractFormInformation,
  statusOptions,
} from 'constants/contractsConstants';

import { clinicianContractValidationSchema } from 'helpers/validation/clinicianContractValidationSchema';
import handleFormSubmitResponse from 'helpers/handleFormSubmitResponse';

import { applyGridTerms, calculatedAnnualRateValue, calculatedContractedHoursValue } from 'helpers/ClinicianConracts/applyGridTerms';
import findCompensationGrid from 'helpers/ClinicianConracts/findCompensationGrid';
import {
  changeEngagementHandler,
  changeContractTypeHandler,
  changeClinicianHandler,
  changeFacilityHandler,
  resetPayrollDetails,
} from 'helpers/ClinicianConracts/confirmChangeHandlers';
import addToMemoCallback from 'helpers/ClinicianConracts/addToMemoCallback';
import prepareClinicianContractFormData from 'helpers/ClinicianConracts/prepareClinicianContractFormData';
import loadClinicianContractFormData from 'helpers/ClinicianConracts/loadClinicianContractFormData';

import useData from 'hooks/useData';

import PayrollDetailsSubform from './PayrollDetailsSubform';
import AppliedCompensationGridInfo from './AppliedCompensationGridInfo';
import SelectStaffCategoryModal from './SelectStaffCategoryModal';
import ConfirmModal from './ConfirmModal';
import CompensationTermsInfo from 'containers/CompensationGrids/CompensationGridDetails/CompensationTermsInfo';
import CallTermsInfo from 'containers/CompensationGrids/CompensationGridDetails/CallTermsInfo';
import StipendsInfo from 'containers/CompensationGrids/CompensationGridDetails/StipendsInfo';
import AdditionalCompensationsInfo from 'containers/CompensationGrids/CompensationGridDetails/AdditionalCompensationsInfo';

interface IConfrimModalProps {
  fieldName: string,
  prevValue: string | number,
};

const ClinicianContractForm = () => {
  const navigate = useNavigate();
  const { onModalOpen, onModalClose } = useContext(ModalContext);

  const [searchParams] = useSearchParams();
  const { clinicianContractId } = useParams();
  const { onToastOpen } = useToast();

  const facilities = useData(FacilityAPI.getAll, { relation: 'ClinicianContract' })?.data.objects;
  const clinicians = useData(ClinicianAPI.getAll, { per_page: 1000 })?.data.objects;
  const contractedHours = useData(CompensationGridAPI.getContractedHours);

  const [facilitiesOptions, setFacilitiesOptions] = useState<ISelectOption[]>([]);
  const [cliniciansOptions, setCliniciansOptions] = useState<ISelectOption[]>([]);
  const [gridTermsApplied, setGridTermsApplied] = useState(false);
  const [compensationGrid, setCompensationGrid] = useState<ICompensationGridDetails>();
  const [compensationScale, setCompensationScale] = useState<ICompensationScale>();
  const [suitableRate, setSuitableRate] = useState<ICompensationRate>();
  const [selectedContractedHour, setSelectedContractedHour] = useState<IContractedHour>();
  const [compScalesForModal, setCompScalesForModal] = useState<ICompensationScale[]>([]);
  const [confirmModalProps, setConfirmModalProps] = useState<IConfrimModalProps>({fieldName: '', prevValue: ''});
  const [amendmentsPresent, setAmendmentsPresent] = useState(false);

  const [clinicianContractFormInformation, setClinicianContractFormInformation] = useState<IClinicianContractForm>(
    initialClinicianContractFormInformation
  );

  useEffect(() => {
    if (facilities) {
      setFacilitiesOptions(facilities.map((facility: IFacility) => ({ id: facility.id, name: facility.name })));
    };
  }, [facilities]);

  useEffect(() => {
    if (clinicians) {
      setCliniciansOptions(clinicians.map((clinician: IClinician) => ({
        id: clinician.id,
        name:
          `${clinician.first_name} ${clinician.last_name}${clinician.maiden_name ? ` (${clinician.maiden_name}),` :','} ${clinician.title}`,
      })));
    };
  }, [clinicians]);

  const resetFormCallback = (
    values: IClinicianContractForm,
    fieldName: string,
    setFieldTouched: any,
    setFieldValue: any,
    setValues: any,
  ) => {
    setGridTermsApplied(false);
    findCompensationGrid(values.clinician_id, values.facility_id, values.contract_type, values.payroll_details.engagement,
      setFieldTouched, setFieldValue, clinicians, setCompensationGrid, facilitiesOptions
    );
    resetPayrollDetails(values, setFieldValue, setValues, clinicians, fieldName);
    onModalClose(confirmModalTitle);
  };

  useEffect(() => {
    if (clinicianContractId && facilitiesOptions && clinicians) {
      ClinicianContractAPI.getDetails(Number(clinicianContractId)).then((data: IClinicianContractDetails) => {
        setAmendmentsPresent(data.payroll_details.length > 1);
        const formData = loadClinicianContractFormData(data);
        if (data.compensation_grid) {
          setGridTermsApplied(true);
          setSelectedContractedHour(data.compensation_scale.contracted_hour);
          setCompensationScale(data.compensation_scale);
          setSuitableRate(data.compensation_scale.compensation_rates.find(rate => rate.id === data.compensation_rate_id));
          setCompensationGrid(data.compensation_grid);
        } else {
          const clinician_title = clinicians
            .find((clinician: IClinician) => clinician.id === formData.clinician_id)?.title as ClinicianTitleType;
          const engagement = formData.payroll_details.engagement as ContractEngagementType;
          CompensationGridAPI.findGrid({
            facility_id: Number(formData.facility_id),
            contract_type: formData.contract_type as ContractType,
            clinician_title: clinicianTitleToCompensationGridClinicianTitleMapper[clinician_title],
            engagement: contractEngagementToCompensationGridEngagementMapper[engagement],
          }).then((data) => {
            setCompensationGrid(data);
            const facilityName = facilitiesOptions.find(facility => facility.id === formData.facility_id)?.name;
            const clinicianTitleOption = clinicianTitleOptions.find(option => option.id === data.clinician_title)?.name;
            const contractTypeOption = contractTypeOptions.find(option => option.id === data.contract_type)?.name;
            const engagementOption = contractEngagementTypeOptions.find(option => option.id === data.engagement)?.name;
            formData.compensation_grid_name = `${facilityName}, ${clinicianTitleOption}, ${contractTypeOption}, ${engagementOption}`;
          });
        }
        setClinicianContractFormInformation(formData);
      });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clinicianContractId, facilitiesOptions, clinicians]);

  if (!(clinicians && facilities)) return null;

  return(
    <Formik
      initialValues={clinicianContractFormInformation}
      enableReinitialize={true}
      validationSchema={() => clinicianContractValidationSchema(clinicians)}
      validateOnBlur
      onSubmit={async (values, { setFieldError }) => {
        const data = prepareClinicianContractFormData(values);
        let response;
        if (clinicianContractId) {
          response = await ClinicianContractAPI.updateContract(Number(clinicianContractId), data);
        } else {
          response = await ClinicianContractAPI.createContract(data);
        }
        handleFormSubmitResponse(response, setFieldError, onToastOpen, navigate, searchParams.get('redirectTo') as string);
      }}
    >
      {({ errors, handleSubmit, dirty, values, setFieldValue, setFieldTouched, setValues }) => (
        <Form>
          <SectionContainer title='Contracted Parties'>
            <InputFieldsWrapper>
              <SelectWithSearch
                name='clinician_id'
                label='Clinician'
                options={cliniciansOptions}
                width='ultra-wide'
                required
                disabled={amendmentsPresent}
                skipPermissionsCheck
                onChange={(newValue: any, prevValue: any) => {
                  changeClinicianHandler(Number(newValue.id), prevValue.id, values, setFieldValue, setFieldTouched, gridTermsApplied,
                    setConfirmModalProps, onModalOpen, clinicians, setCompensationGrid, facilitiesOptions);
                }}
              />
              <DatePicker
                name='grad_date'
                label='Grad Date'
                width='narrow'
                disabled
                skipPermissionsCheck
              />
              <Input name='experience' label='Experience' width='narrow' disabled skipPermissionsCheck/>
              <SelectWithSearch
                name='facility_id'
                label='Facility'
                options={facilitiesOptions}
                width='ultra-wide'
                required
                disabled={amendmentsPresent}
                skipPermissionsCheck
                onChange={(newValue: any, prevValue: any) => {
                  changeFacilityHandler(Number(newValue.id), prevValue.id, values, setFieldValue, setFieldTouched, gridTermsApplied,
                    setConfirmModalProps, onModalOpen, clinicians, setCompensationGrid, facilitiesOptions);
                }}
              />
            </InputFieldsWrapper>
          </SectionContainer>
          <SectionContainer title='Contract Details'>
            <InputFieldsWrapper>
              {clinicianContractId &&
                <Select
                  name='status'
                  label='Status'
                  options={statusOptions}
                  width='extra-narrow'
                  skipPermissionsCheck
                  allowEmpty={false}
                />
              }
              <Select
                name='contract_type'
                label='Contract Type'
                options={contractTypeOptions}
                required
                disabled={amendmentsPresent}
                width='extra-narrow'
                onChange={(contractType: ContractType, prevValue: ContractType | '') => {
                  changeContractTypeHandler(contractType, prevValue, values, setFieldValue, setFieldTouched, gridTermsApplied,
                    setConfirmModalProps, onModalOpen, clinicians, setCompensationGrid, facilitiesOptions);
                }}
                skipPermissionsCheck
              />
              <Select
                name='payroll_details.engagement'
                label='Engagement'
                options={contractEngagementTypeOptions}
                required
                disabled={amendmentsPresent}
                width='extra-narrow'
                onChange={(engagement: ContractEngagementType, prevValue: ContractEngagementType | '') => {
                  changeEngagementHandler(engagement, prevValue, values, setFieldValue, setFieldTouched, gridTermsApplied,
                    setConfirmModalProps, onModalOpen, clinicians, setCompensationGrid, facilitiesOptions);
                  if (gridTermsApplied && engagement === 'full_time') {
                    setFieldValue('payroll_details.contracted_hours',
                      calculatedContractedHoursValue(
                        selectedContractedHour as IContractedHour,
                        engagement,
                        Number(values.payroll_details.fte)
                      )
                    );
                    setFieldValue('payroll_details.annual_rate',
                      calculatedAnnualRateValue(
                        (suitableRate as ICompensationRate).annual_rate,
                        engagement,
                        Number(values.payroll_details.fte)
                      )
                    );
                  }
                }}
                onBlur={() => {
                  setFieldValue('payroll_details.fte', '');
                }}
                skipPermissionsCheck
              />
              {values.payroll_details.engagement === 'part_time' &&
                <Input
                  name='payroll_details.fte'
                  label='FTE'
                  type='fte'
                  width='extra-narrow'
                  required
                  disabled={amendmentsPresent}
                  skipPermissionsCheck
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    if (gridTermsApplied) {
                      setFieldValue('payroll_details.contracted_hours',
                        calculatedContractedHoursValue(
                          selectedContractedHour as IContractedHour,
                          values.payroll_details.engagement,
                          Number(e.target.value)
                        )
                      );
                      setFieldValue('payroll_details.annual_rate',
                        calculatedAnnualRateValue(
                          (suitableRate as ICompensationRate).annual_rate,
                          values.payroll_details.engagement,
                          Number(e.target.value)
                        )
                      );
                    }
                  }}
                />
              }
            </InputFieldsWrapper>
            <InputFieldsWrapper>
              <DatePicker
                name='payroll_details.execution_date'
                label='Execution Date'
                width='extra-narrow'
                disabled={amendmentsPresent}
                disableFuture={false}
                skipPermissionsCheck
              />
              <DatePicker
                name='payroll_details.effective_date'
                label='Effective Date'
                width='extra-narrow'
                disabled={amendmentsPresent}
                disableFuture={false}
                skipPermissionsCheck
                required
              />
              <Input
                name='payroll_details.contract_term'
                label='Contract Term, years'
                type='twoDigits'
                disabled={amendmentsPresent}
                width='extra-narrow'
                skipPermissionsCheck
              />
            </InputFieldsWrapper>
            {clinicianContractId &&
              <InputFieldsWrapper>
                <DatePicker
                  name='resign_date'
                  label='Term/Resign Date'
                  width='extra-narrow'
                  disableFuture={false}
                  skipPermissionsCheck
                />
                <DatePicker
                  name='last_day_of_service'
                  label='Last Day of Service'
                  width='extra-narrow'
                  disableFuture={false}
                  skipPermissionsCheck
                />
                <Input
                  name='contract_end_type'
                  label='Contract End Type'
                  width='extra-narrow'
                  skipPermissionsCheck
                />
              </InputFieldsWrapper>
            }
          </SectionContainer>
          <SectionContainer title='Compensation Grid'>
            { !gridTermsApplied ?
              <InputFieldsWrapper>
                <Input
                  name='compensation_grid_name'
                  label='Compensation Grid'
                  width='ultra-wide'
                  disabled
                  skipPermissionsCheck
                />
                <TextButton
                  color='blue'
                  disabled={!values.compensation_grid_name}
                  onClick={() => {
                    applyGridTerms(setFieldValue, values, compensationGrid, onToastOpen, contractedHours, setSelectedContractedHour,
                      setCompensationScale, setSuitableRate, setGridTermsApplied, setCompScalesForModal, onModalOpen);
                  }}
                >
                  Apply Grid Terms
                </TextButton>
              </InputFieldsWrapper>
              :
              <AppliedCompensationGridInfo
                compensationGridName={values.compensation_grid_name}
                staffCatergory={compensationScale?.staff_category as string}
                selectedContractedHour={selectedContractedHour as IContractedHour}
              />
            }
          </SectionContainer>
          {gridTermsApplied && compensationScale && selectedContractedHour &&
            <PayrollDetailsSubform
              amendmentsPresent={amendmentsPresent}
              contract_type={values.contract_type as ContractType}
              payroll_details={values.payroll_details}
              selectedContractedHour={selectedContractedHour}
              contractCreation={!clinicianContractId}
            />
          }
          {gridTermsApplied && compensationGrid &&
            <>
              <CompensationTermsInfo
                compensationGrid={compensationGrid}
                facility={facilities.find((facility: IFacility) => facility.id === values.facility_id)}
              />
              <CallTermsInfo
                compensationGrid={compensationGrid}
                addToMemoCallback={(value: any) => addToMemoCallback(value, setFieldValue, values)}
              />
              <StipendsInfo
                compensationGrid={compensationGrid}
                addToMemoCallback={(value: any) => addToMemoCallback(value, setFieldValue, values)}
              />
              <AdditionalCompensationsInfo
                compensationGrid={compensationGrid}
                addToMemoCallback={(value: any) => addToMemoCallback(value, setFieldValue, values)}
              />
              {(values.payroll_memo !== '' || !clinicianContractId) &&
                <>
                  <SectionContainer title="Payroll Memo">
                    <TextEditor name='payroll_memo'/>
                  </SectionContainer>
                  <SectionContainer title="Admin Memo">
                    <TextEditor name='admin_memo'/>
                  </SectionContainer>
                </>
              }
            </>
          }
          <ActionsBar>
            <OutlinedButton
              onClick={() => navigate(-1)}
            >
              Cancel
            </OutlinedButton>
            <SubmitButton
              disabled={!dirty || !!Object.values(errors).length}
              onClick={(values: FormEvent<HTMLFormElement>) => handleSubmit(values)}
            >
              Save Contract
            </SubmitButton>
          </ActionsBar>
          <SelectStaffCategoryModal
            compensationScales={compScalesForModal}
            compensationGrid={compensationGrid as ICompensationGridDetails}
            contractedHours={contractedHours}
            values={values}
            setFieldValue={setFieldValue}
            setSelectedContractedHour={setSelectedContractedHour}
            setCompensationScale={setCompensationScale}
            setSuitableRate={setSuitableRate}
            setGridTermsApplied={setGridTermsApplied}
          />
          <ConfirmModal
            fieldProps={confirmModalProps}
            values={values}
            setFieldValue={setFieldValue}
            setFieldTouched={setFieldTouched}
            setValues={setValues}
            resetFormCallback={resetFormCallback}
          />
        </Form>
      )}
    </Formik>
  );

};

export default ClinicianContractForm;
